import React from "react";
import "./header.css";
import { Container, Nav, Navbar, Row } from "react-bootstrap-v5";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import Logo from "../../assets/images/lovetech1.png";
import { menuItems } from "../../menuItems";
import MenuItems from "./MenuItems";

const Header = () => {
  return (
    <>
      <div id="wrapper" className="page-wrapper">
        <div className="mxr__header">
          <div className="mxr__header-top">
            <Container>
              <Row>
                <div className="col-md-6 col-sm-12 mxr__header-align-left">
                  <span class="mxr__header-phone">
                    Call Us Today! (+234) 70 8906 8401, 80 3713 9542
                  </span>
                  <span className="mxr__header-separator">|</span>
                  <span className="mxr__header-email">
                    <a href="mailto:info@lovetech.com.ng">info@lovetech.com.ng</a>
                  </span>
                </div>
                <div className="col-md-6 col-sm-12 mxr__header-align-right">
                  <a href="https://www.facebook.com/">
                    <FaFacebookF size={18} />
                  </a>
                  <a href="https://twitter.com/">
                    <FaTwitter size={18} />
                  </a>
                  <a href="https://www.instagram.com/">
                    <FaInstagram size={18} />
                  </a>
                  <a href="https://www.linkedin.com/">
                    <FaLinkedinIn size={18} />
                  </a>
                </div>
              </Row>
            </Container>
          </div>
          <div className="mxr__header-navbar">
            <Navbar expand="lg" varient="light">
              <Container>
                <Navbar.Brand href="#home">
                  <img
                    src={Logo}
                    className="mxr__header-logo"
                    alt="Lovetech"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto" eventKey="1">
                    {menuItems.map((menu, index) => {
                      const depthLevel = 0;
                      return (
                        <MenuItems
                          items={menu}
                          key={index}
                          depthLevel={depthLevel}
                        />
                      );
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
