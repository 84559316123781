import React from 'react'
import {Row,Col, Container, Card} from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';

function Benifits(props){
  return (
   <div className='mxr__benifits mxr__section mxr__hireus '>
    <Container>
    {props.data.map((list,index)=>(
      <>
        <Row>
        <Col md={6} sm={12}>
        {list.images}  
        </Col>
        <Col md={6} sm={12} >
          <h3 className='mxr__section-title'>{list.heading}</h3>
          {list.pharagraphs.map((read,index)=>(
            <p>{read}</p>
          ))}
           <div style={{justifyContent: 'space-evenly'}}>
            <Row>
            <Col sm={12} md={4}>
              <Card>
              <Link to="https://digigirls-training-2023.teachable.com/p/digihubs-data-analysis" className="mxr__section-btn" style={{ margin: '10px' }}>{"  "}Data Analysis{"  "}</Link>
              </Card>
            </Col>
            </Row>
          </div>
        </Col>
        </Row>
      </>
    ))}
    </Container>
 </div>
  )
}


export default Benifits
