import React from 'react'
import './advance.css';
import { Container,Row,Col } from 'react-bootstrap-v5';
import {FaSearch,FaChartLine,FaIdCardAlt} from 'react-icons/fa';

const Advance = () => {
  return (
    <div className='mxr__advance mxr__section bg_3steps'>
      <Container>
        <h3 className='mxr__section-title'>ADVANCE FEATURES</h3>
        <Row>
            <Col md={4} sm={12}>
              <div className='bg_3steps_icon'>
               <FaSearch size={40}/>
               </div>
               <h5>Intro to Digital Skills</h5>
               <p>Understanding the core principles of Digital Skills and exploring various technologies to enhance Digital Skills..</p>
            </Col>
            <Col md={4} sm={12}>
               <div className='bg_3steps_icon'>
               <FaChartLine size={40}/>
               </div>
               <h5>ANALYTICS</h5>
               <p>Exploring the varied use cases of technologies in various industries (real estate, design, healthcare, education, etc.), and discerning the advantages and obstacles associated with integrating these solutions for Digital Skills..</p>
            </Col>
            <Col md={4} sm={12}>
              <div className='bg_3steps_icon'>
               <FaIdCardAlt size={40}/>
               </div>
               <h5>Technology Experiences</h5>
               <p>Exploring the tools and software used for digital skill acquisition, and designing practical training scenarios that enhance digital skill acquisition, exemplified through a real-life case..</p>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Advance
