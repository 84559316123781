import React from 'react'
import './advance.css';
import { Container,Row,Col } from 'react-bootstrap-v5';
import {FaSearch,FaChartLine,FaIdCardAlt} from 'react-icons/fa';

const Advance = () => {
  return (
    <div className='mxr__advance mxr__section bg_3steps'>
      <Container>
        <h3 className='mxr__section-title'>ADVANCE FEATURES</h3>
        <Row>
            <Col md={4} sm={12}>
              <div className='bg_3steps_icon'>
               <FaSearch size={40}/>
               </div>
               <h5>Intro to XR</h5>
               <p>Understanding the fundamentals of Extended Reality (XR) and Exploring the different types of XR technologies (Virtual Reality, Augmented Reality, Mixed Reality).</p>
            </Col>
            <Col md={4} sm={12}>
               <div className='bg_3steps_icon'>
               <FaChartLine size={40}/>
               </div>
               <h5>ANALYTICS</h5>
               <p>Examining the diverse applications of XR across industries (real estate, design, healthcare, education, etc.) and Identifying the benefits and challenges of implementing XR solutions.</p>
            </Col>
            <Col md={4} sm={12}>
              <div className='bg_3steps_icon'>
               <FaIdCardAlt size={40}/>
               </div>
               <h5>Creating XR Experiences</h5>
               <p>Discovering the tools and software used for XR content creation and designing realistic and immersive training scenarios in XR, with a pratical example.</p>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Advance
