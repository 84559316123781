import React from 'react'
import Benifits from '../Trainings/Benifits';
import images from '../../assets/icons/benifits/benifits.png'
import Advance from './Advance';
import Advance2 from './Advance2';
import Pictures from './Pictures';
import WhyChoose360 from './WhyChoose360';
import { Innerbanner, Apply } from '../../components';
import TechnologyWork from '../vr/TechnologyWork';
import { Helmet } from 'react-helmet';

const dataList =[
  { id:"1",
   heading:"BENEFITS OF THE TRAINING",
   images:<img src={images} alt='benifits of vr 360'/>,
   pharagraphs:[
    "XR training offers a hands-on approach to learning, allowing participants to practice and refine their skills in a realistic virtual environment. This practical experience translates into increased competence and confidence when applying those skills in real-life situations. Particiapant should be able to build there own XR applications without the stress of pruchaing high-end gaming machines",
  ],
  },
];
const bannerdata = {
  img: 'vr-360-banner.jpg',
  title: "XR training | lovetech.",
  text: "Create, edit, share. Upload 360/VR photos. Easily create virtual tours. Share anywhere!"
}

const readytotalk = {
  img: 'ready.jpg',
  title: "READY TO START",
  text: "We will love to have you as you add up to your XR skills, with this skills you can be a better frontend engineer.",
  button:"LET'S DO IT",
}
function Training() {
  return (
    <div>
      <Helmet>
        <title>XR training | VR 360 | Lovetech</title>
        <meta name="description" content="The significance of XR training in VR, AR, Mixed Reality cannot be overstated. By leveraging XR technologies, to a realistic virtual environments that simulate the final stages of a project." />
      </Helmet>
      <Innerbanner banner={bannerdata}/>
      <Benifits data={dataList}/>
      <Advance />
      <Advance2 />
      <WhyChoose360 />
      <Pictures />
      <Apply discuss={readytotalk}/>
    </div>
  )
}

export default Training
