import React from 'react'
import { Innerbanner, Readytotalk } from '../../components'
import images from '../../assets/icons/benifits/solar-power.png'
import solar from '../../assets/icons/innerbanner/technology-solar-energy.jpeg'
import Benifits from '../vr360/Benifits'
import {FaLaptop,FaMobileAlt,FaThumbsUp,FaDice} from 'react-icons/fa'
import WhyChoose from '../../components/WhyChoose'
import TechnologyServices from '../../components/TechnologyServices'
import training from '../../assets/icons/technologyservices/unity/traing.png'
import config from '../../assets/icons/technologyservices/unity/config.png'
import development from '../../assets/icons/technologyservices/unity/development.png'
import talk from '../../assets/icons/technologyservices/unity/walk.png'
import application from '../../assets/icons/technologyservices/unity/application.png'
import archetecture from '../../assets/icons/technologyservices/unity/archetecture.png'
import WhyShould from '../../components/WhyShould'
import WhyChooseSolr from '../../components/WhyChooseSolar'
import { Helmet } from 'react-helmet'

const bannerdata = {
  img: 'technology-unity-app.jpg',
  title: "Tesla Technology",
  text: "We have a full experience of Tesla Inverters, and deployed to some couple of client, and we’re ready to help you make your home off-grid."
}

const dataList =[
  { id:"1",
   heading:"TESLA & SOLAR ENERGY TECHNOLOGY",
   images:<img src={images} alt='tesla'/>,
   pharagraphs:[
    "Tesla have one of the most sofisicated power inverters that can be used to power homes, businesses, and even entire cities. Our team of experts can help you design and install a customized solar energy system for your unique situation.",
  ],
  },
];

const readytotalk = {
  img: 'MR.jpg',
  title: "READY TO DISCUSS YOUR PROJECT? ",
  text: "We would love to hear about your idea and make it happen. You come to us with your idea, and we do all of the planning and costing for you.",
  button:"CONTACT US",
}

const benifits=[
  {
    heading:"TESLA INVERTER DEVELOPMENT SERVICES WE PROVIDE",
    lists:[
      {
       title:"POWER BACKUP DEVELOPMENT",
       text:"We can make your application accessible on a number of platforms and app stores. The possibilities of multi platform game development on Unity are especially vast, so there is great freedom to work with.",
       icon:<FaLaptop size={30} />,
      },
      {
        title:"SOLAR & INVERTER SERVICES",
        text:"Program-Ace together with our gaming devision Game-Ace are not afraid to tackle complex projects, such as desktop, console or mobile games to deliver experience for a global audience.",
        icon:<FaDice size={30} />,
      },
      {
        title:"ROOF TOP DEVELOPMENT",
        text:"Put your product in the hands of millions of users. If you choose to use only one platform for unity mobile development like Android or iOS development, we can also get it done.",
        icon:<FaMobileAlt size={30} />,
      },
      {
        title:"SOLAR POWERED WATER BACKUP DEVELOPMENT",
        text:"Innovative XR technologies are changing the landscape of gaming and entertainment. We can bring your AR/VR/MR project to fruition with this highly-sophisticated platform.",
        icon:<FaThumbsUp size={30} />,
      },
    ]
  }
]

const services=[
  {
    heading:"RENEWABLE ENERGY SOLUTIONS WE OFFER",
    data:[
      {
       title:"SOLAR INVERTER",
       text:"Our Unity engineers, who strive for perfection in precise architecture and design, may be a valuable asset to your project, delivering high-performance visualisation and application design as well as assuring a positive user experience.",
       icon:<img src={archetecture} alt="application"/>,
      },
      {
        title:"TRAINING FOR INDUSTRY-SPECIFIC OBJECTIVES",
        text:"We use Unity IDE to create highly realistic and efficient solutions for military, industry, and other industries, thanks to our hands-on experience in completely immersive training environments.",
        icon:<img src={training} alt="webservies"/>,
      },
      {
        title:"WALKTHROUGHS IN 360 DEGREES",
        text:"We use Unity graphics to create interactive, 360-degree walkthroughs and flythroughs as a visual presentation of actual sites, possible buildings, or construction projects, concept art, and more to meet the demands of your business.",
        icon:<img src={talk} alt="support"/>,
      },
      {
        title:"CONFIGURATORS OF PRODUCTS",
        text:"You will be able to show off your product to customers, business partners, and investors using an unique configurator created by our team.",
        icon:<img src={config} />,
      },
      {
        title:"APPLICATIONS FOR GAMIFICATION",
        text:"Changing your application’s nature to that of a game is a terrific method to make it more exciting and engaging for users. We’ve been designing gamification solutions for a long time and will figure out the best way to make the shift.",
        icon:<img src={application} />,
      },
      {
        title:"DEVELOPMENT OF A DIGITAL TWIN",
        text:"Our knowledge of the topic enables us to create digital models, simulations, and systems that accurately duplicate real-world environments, objects, and processes.",
        icon:<img src={development} />,
      },

    ]
  }
]

const TeslaInverters = () => {
  return (
    <div>
      <Helmet>
        <title>Tesla Tech - LOVETECH</title>
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <Benifits data={dataList}/>
      <WhyChooseSolr />
      <WhyChoose benifit={benifits}/>
      <Readytotalk discuss={readytotalk} />
      <TechnologyServices service={services}/>
     
    </div>
  )
}

export default TeslaInverters
