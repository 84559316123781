import React from 'react'
import { Innerbanner, Process, Readytotalk } from '../../components'
import Landscape from './Landscape'
import Benifits from '../vr360/Benifits'
import images from '../../assets/icons/benifits/solar-power.png'
import { Helmet } from 'react-helmet';

const bannerdata = {
  img: 'services-research and innovation.jpg',
  title: "Solar Technology",
  text: "We will provide you with Solar Technology and renewable energy experties, and we’re ready to help you make your home go off-grid."
}

const dataList = [
  {
    id: "1",

    images: <img src={images} alt='solar power' />,
    pharagraphs:[
      "Solar energy is a clean and abundant resource that can be used to power homes, businesses, and even entire cities. Our team of experts can help you design and install a customized solar energy system for your unique situation.",
      "We offer a variety of options, including rooftop solar panels, ground-mounted solar arrays, and off-grid solar systems. We also provide maintenance and monitoring services to ensure that your system is running at its peak performance. ",
    ],
  },
];

const readytotalk = {
  img: 'python.webp',
  title: "READY TO DISCUSS YOUR PROJECT? ",
  text: "We would love to hear about your idea and make it happen. You come to us with your idea, and we do all of the planning and costing for you.",
  button: "CONTACT US",
}

const SolarTechnology = () => {
  return (
    <div>
      <Helmet>
        <title>Solar Tech | LOVETECH</title>
        <meta name="description" content="LOVETECH is a software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally." />
        </Helmet>
      <Innerbanner banner={bannerdata} />
      <Benifits data={dataList} />
      <Landscape />
      <Readytotalk discuss={readytotalk} />
      <Process title="OUR SOLAR DEVELOPMENT PROCESS" />
    </div>
  )
}

export default SolarTechnology
