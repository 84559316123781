import React from 'react'
import { Container,Tab,Tabs,Row,Col } from 'react-bootstrap-v5'
import solarfriends from '../assets/icons/technologyservices/whyshould/solarfriends.png'
import solarhome from '../assets/icons/technologyservices/whyshould/solarhome.png'
import simulatingimage from '../assets/icons/technologyservices/whyshould/simluatingimage.png'
import sound from '../assets/icons/technologyservices/whyshould/sound.jpg'


const WhyShouldUnity = () => {
  return (
    <div className='mxr__whyshouldunity mxr__section'>
      <Container>
        <h3 className='mxr__section-title'>WHY SHOULD YOU OPT FOR SOLAR?</h3>
         <Tabs className="mb-3 mxr__tabs">
         <Tab  eventKey="Green Energy" title="Green Energy">
            <Row>
                <Col md={6} sm={12}>
                   <img src={solarfriends} alt="Real Estate"/>
                </Col>
                <Col md={6} sm={12}>
                    <p>Solar energy is an increasingly popular choice for households and businesses looking to reduce their energy costs and become more environmentally friendly. Solar power is a renewable energy source that produces no pollution, is virtually free to use, and is available in abundance. With solar energy, you can save money on your energy bills, reduce your carbon footprint, and even earn money through government incentives.</p>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey='Reliabilty and Sustainability' title='Reliabilty and Sustainability'>
                <Row>
                    <Col md={6} sm={12}>
                      <img src={solarhome} alt="automotive" />
                    </Col>
                    <Col md={6} sm={12}>
                      <p>Solar energy is more reliable than traditional energy sources, and it can be used to power almost anything. Solar panels are incredibly efficient, converting sunlight into electricity without producing any harmful emissions.</p>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey='Off-Grid Power Generation' title='Off-Grid Power Generation'>
                <Row>
                    <Col md={6} sm={12}>
                      <img src={simulatingimage} alt="virtual trade show" />
                    </Col>
                    <Col md={6} sm={12}>
                      <p>With solar energy, you can generate your own electricity, reducing your reliance on the grid and increasing your energy independence. Additionally, solar energy is infinitely renewable and relatively easy to maintain. Solar energy is also incredibly cost-effective. Installation costs are coming down, and you can often recoup your investment in just a few years.</p>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey='Government Infrastructure and Support' title='Government Infrastructure and Support'>
                <Row>
                    <Col md={6} sm={12}>
                      <img src={solarhome} alt="" />
                    </Col>
                    <Col md={6} sm={12}>
                      <p>Government incentives can also help you save money on your solar system. Additionally, solar energy is becoming increasingly popular with businesses, as it can help them reduce their energy costs and boost their sustainability efforts. Overall, solar energy is a great choice for households and businesses looking to reduce their energy costs and become more environmentally friendly.</p>
                    </Col>
                </Row>
            </Tab>
         </Tabs>
      </Container> 
    </div>
  )
}

export default WhyShouldUnity
