import React from "react";
import "./advance2.css";
import { Container, Row, Col } from "react-bootstrap-v5";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  FaTencentWeibo,
  FaLayerGroup,
  FaIdCardAlt,
  FaFilePowerpoint,
  FaTv,
  FaGlobe,
} from "react-icons/fa";
import timelines from "../../assets/images/advance_features.png";

const Advance2 = () => {
  return (
    <div className="mxr__advance2 mxr__section">
      <Container>
        <h3 className="mxr__section-title">Advance Features</h3>
        <Row>
          <Col md={7} sm={12}>
            <img src={timelines} alt="" />
          </Col>
          <Col md={5} sm={12}>
            <VerticalTimeline>
              <VerticalTimelineElement className="vertical-timeline-element--work">
                <div className="icon_timeline">
                  <FaTencentWeibo />
                </div>
                <div className="advance_Features_heading">
                  <h4>ONLINE LEARNING</h4>
                  <p>
                    Access digital courses and materials online, making learning convenient and accessible.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className="vertical-timeline-element--work">
                <div className="icon_timeline">
                  <FaLayerGroup />
                </div>
                <div className="advance_Features_heading">
                  <h4>INTERACTIVE LESSONS</h4>
                  <p>
                    Engage with interactive lessons that facilitate hands-on learning and skill acquisition.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className="vertical-timeline-element--work">
                <div className="icon_timeline">
                  <FaIdCardAlt />
                </div>
                <div className="advance_Features_heading">
                  <h4>CERTIFICATION</h4>
                  <p>
                    Obtain certifications to validate your digital skill acquisition and enhance your career opportunities.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className="vertical-timeline-element--work last">
                <div className="icon_timeline">
                  <FaFilePowerpoint />
                </div>
                <div className="advance_Features_heading">
                  <h4>SELF-PACED LEARNING</h4>
                  <p>
                    Learn at your own pace, allowing flexibility and tailored skill development.
                  </p>
                </div>
              </VerticalTimelineElement>
  
            </VerticalTimeline>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Advance2;
