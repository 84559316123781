import React from 'react'
import { Container,Row,Col,Tabs,Tab } from 'react-bootstrap-v5'
import {FaPaintBrush,FaUser,FaSearchDollar,FaPenNib} from 'react-icons/fa';
import solarfriends from '../../assets/icons/technologyservices/whyshould/solarfriends.png'
import solarhome from '../../assets/icons/technologyservices/whyshould/solarhome.png'
import simulatingimage from '../../assets/icons/technologyservices/whyshould/simluatingimage.png'
import './landscape.css'

const Landscape = () => {
  return (
    <>
    <div className='mxr__landscape mxr__section bg_3steps'>
      <Container>
        <h3 className='mxr__section-title'>SERVICE LANDSCAPE</h3>
        <Row>
          <Col md={6} sm={12}>
          <div className='bg_3steps_icon'>
            <FaPaintBrush size={30}/>
            </div>
            <h5>TECHNOLOGY RESEARCH</h5>
            <p>We will design and engineer a unique and effective renewable technological solution that will generate a competitive advantage and become a robust tool for your success, helping you stand out on the market.</p>
          </Col>
          <Col md={6} sm={12}>
          <div className='bg_3steps_icon'>
            <FaUser size={30} />
          </div>
            <h5>Energy Audit & Design</h5>
            <p>Our team of expert will do energy audit and design a rubust technology that will fit perfectly to your consumption.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
          <div className='bg_3steps_icon'>
            <FaSearchDollar size={30}/>
          </div>
            <h5>Waranty</h5>
            <p>Our service comes with assurance of 2years waranty and 20years gauranttee, This is how sure we are with our technology and deployment of service.</p>
          </Col>
          <Col md={6} sm={12}>
          <div className='bg_3steps_icon'>
            <FaPenNib size={30} />
          </div>
            <h5>MVP DEVELOPMENT</h5>
            <p>We will create a minimum viable product for your software startup, so that you can get quick market feedback without a significant investment and keep polishing your product until it is a complete fit for its market and target audience.</p>
          </Col>
        </Row>
        </Container>
        </div>
        <div className='mxr__tech mxr__section white_simple_tabs'>
          <Container>
            <h3 className='mxr__section-title'>HOW IT WORKS</h3>
        <Tabs className="mb-3 mxr__tabs">
          <Tab eventKey="Green Energy" title="Green Energy">
           <Row>
            <Col md={6} sm={12} className="landscap_left_sec">
              <img src={solarfriends} alt="reseach"/>
              </Col>
              <Col md={6} sm={12}>
                <p>Solar energy is an increasingly popular choice for households and businesses looking to reduce their energy costs and become more environmentally friendly. Solar power is a renewable energy source that produces no pollution, is virtually free to use, and is available in abundance. With solar energy, you can save money on your energy bills, reduce your carbon footprint, and even earn money through government incentives.</p>
              </Col>
           </Row>
          </Tab>
          <Tab eventKey='Reliabilty and Sustainability' title='Reliabilty and Sustainability'>
            <Row>
              <Col md={6} sm={12} className="landscap_left_sec">
               <img src={solarhome} alt="Proof Of Concept" />
              </Col>
              <Col md={6} sm={12}>
                <p>Solar energy is more reliable than traditional energy sources, and it can be used to power almost anything. Solar panels are incredibly efficient, converting sunlight into electricity without producing any harmful emissions.</p>
              </Col >
            </Row>
          </Tab>
          <Tab eventKey='Off-Grid Power Generation' title='Off-Grid Power Generation'>
            <Row>
              <Col md={6} sm={12} className="landscap_left_sec">
                <img src={simulatingimage} alt="Viable Product"/>
              </Col>
              <Col md={6} sm={12}>
                <p>With solar energy, you can generate your own electricity, reducing your reliance on the grid and increasing your energy independence. Additionally, solar energy is infinitely renewable and relatively easy to maintain. Solar energy is also incredibly cost-effective. Installation costs are coming down, and you can often recoup your investment in just a few years.</p>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        </Container> 
        </div>
      
      </>
  )
}

export default Landscape
