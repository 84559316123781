import React from 'react'
import {Row,Col, Container, Card} from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';

function Benifits(props){
  return (
   <div className='mxr__benifits mxr__section mxr__hireus '>
    <Container>
    {props.data.map((list,index)=>(
      <>
        <Row>
        <Col md={6} sm={12}>
        {list.images}  
        </Col>
        <Col md={6} sm={12} >
          <h3 className='mxr__section-title'>{list.heading}</h3>
          {list.pharagraphs.map((read,index)=>(
            <p>{read}</p>
          ))}
           <div style={{justifyContent: 'space-evenly'}}>
            <Row>
            <Col sm={12} md={4}>
              <Card>          
              <Link to="/basic-digital-literacy" className="mxr__section-btn" style={{ margin: '10px' }}>{"  "}Basic Digital Literacy{"  "}</Link>
              </Card>
            </Col > 
            <Col sm={12} md={4}>
              <Card>
              <Link to="/softskills" className="mxr__section-btn" style={{ margin: '10px' }}>{"  "}Soft Skills{"  "}</Link>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Link to="/cyber-security" className="mxr__section-btn" style={{ margin: '10px' }}>{"  "}Cyber Security{"  "}</Link>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Link to="/ecommerce" className="mxr__section-btn" style={{ margin: '10px' }}>{"  "}E-commerce{"  "}</Link>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Link to="/data-analysis" className="mxr__section-btn" style={{  margin: '10px' }}>{"  "}Data Analysis{"  "}</Link>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <Link to="/digital-marketing" className="mxr__section-btn" style={{  margin: '10px' }}>{"  "}Digital Marketing{"  "}</Link>
              </Card>
            </Col>
            </Row>
          </div>
        </Col>
        </Row>
      </>
    ))}
    </Container>
 </div>
  )
}


export default Benifits
