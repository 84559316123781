import React from 'react'
import Benifits from './links/DigitalMarketingLink';
import images from '../../assets/icons/benifits/benifits.png'
import Advance from './Advance';
import Advance2 from './Advance2';
import Pictures from './Pictures';
import WhyChoose360 from './WhyChoose360';
import { Innerbanner, DigiGirls } from '../../components';
import TechnologyWork from '../vr/TechnologyWork';
import { Helmet } from 'react-helmet';

const dataList =[
  { id:"1",
   heading:"Get Started with Digital Marketing",
   images:<img src={images} alt='benifits of DigiGirls'/>,
   pharagraphs:[
    "Click on the button below if you Registerd for Digital Marketing Learning path.",
  ],
  },
];
const bannerdata = {
  img: 'vr-360-banner.jpg',
  title: "Digital Marketing | DigiGirls | lovetech.",
  text: "Learn a Digital Skills with DigiHubs!"
}

const readytotalk = {
  img: 'ready.jpg',
  title: "Start Your Application",
  text: "We will love to have you as you add up to your Digital skills.",
  button:"LET'S DO IT",
}
function DigitalMarketing() {
  return (
    <div>
      <Helmet>
        <title>DigiGirls | Digihubs| Lovetech</title>
        <meta name="description" content="For Females aged between 15 - 40 Resident in Nigeria and interested in begnining a career in Tech." />
      </Helmet>
      <Innerbanner banner={bannerdata}/>
      <DigiGirls discuss={readytotalk}/>

      <Benifits data={dataList}/>
      <Advance />
    </div>
  )
}

export default DigitalMarketing
