import React from 'react'
import Benifits from '../digihubs/Benifits';
import images from '../../assets/icons/benifits/benifits.png'
import Advance from './Advance';
import Advance2 from './Advance2';
import Pictures from './Pictures';
import WhyChoose360 from './WhyChoose360';
import { Innerbanner, Apply } from '../../components';
import TechnologyWork from '../vr/TechnologyWork';
import { Helmet } from 'react-helmet';

const dataList =[
  { id:"1",
   heading:"GET STARTING",
   images:<img src={images} alt='benifits of DigiGirls'/>,
   pharagraphs:[
    "DigiHubs (Cybersafe Foundation) offers a hands-on approach to learning, allowing participants to practice and refine their skills in a realistic environment. This practical experience translates into increased competence and confidence when applying those skills in real-life situations. Particiapant should be able to use there acquired skills without the stress.",
  ],
  pharagraphs:[
    "Get stared with your learning path of your choice from the courses below.",
  ],
  },
];
const bannerdata = {
  img: 'vr-360-banner.jpg',
  title: "DigiGirls | lovetech.",
  text: "Learn a Digital Skills with DigiHubs!"
}

const readytotalk = {
  img: 'ready.jpg',
  title: "READY TO START",
  text: "We will love to have you as you add up to your XR skills, with this skills you can be a better frontend engineer.",
  button:"LET'S DO IT",
}
function Training() {
  return (
    <div>
      <Helmet>
        <title>DigiGirls | Digihubs| Lovetech</title>
        <meta name="description" content="For Females aged between 15 - 40 Resident in Nigeria and interested in begnining a career in Tech." />
      </Helmet>
      <Innerbanner banner={bannerdata}/>
      <Benifits data={dataList}/>
      <Advance />
      <Advance2 />
      {/* <WhyChoose360 /> */}
      {/* <Pictures /> */}
      {/* <Apply discuss={readytotalk}/> */}
    </div>
  )
}

export default Training
