import React from "react";
import {
  Features,
  Solution,
  Process,
  Promise,
  Industries,
  Banner,
  Stacks,
  Cta2,
  Research,
  Clients,
  Stats,
} from "../../components";
import { Helmet } from "react-helmet";
import Sticky from "../../components/stickyelements/Sticky";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Lovetech</title>
        <meta
          name="description"
          content="Lovetech is Nigerian based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally."
        />
      </Helmet>
      <Banner />
      <Sticky />
      <Features />
      <Research />
      <Process title="WE SIMPLIFY SOFTWARE DEVELOPMENT PROCESS" />
      <Promise />
      <Stacks />

      <Solution />
      <Cta2 />
      <Industries />
      <Stats />
      <Clients />
    </>
  );
};

export default Home;
